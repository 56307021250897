<template>
	<div class="hotel-list-box">
		<ul class="reserve-flows flex-start flexbox">
			<li :class="(item.current == true ? 'current ' : '') + 'ticket-box  flexbox'" v-for="(item, index) in progress" :key="index">
				<span class="flows-line"></span>
				<a class="flows-text">{{ item.name }}</a>
			</li>
		</ul>
		<!-- <div class="policy-box">
			您正在为刘春琼进行差旅产品预订，对应的差旅政策为：指定酒店价格限制:广州,价格最高:888元/间/夜；其他城市价格限制:687元/间/夜；酒店星级限制:五星级
		</div> -->
		<div class="hotel-inf-box">
			<div class="flight-inf-box hotel-inf-box2">
				<div class="hotel-indexv2"><SearchReply ref="SearchReplyAssembly" @bindSearchHotel="bindSearchHotel" :HotelSearchInfo="HotelSearchInfo"></SearchReply></div>
			</div>
		</div>
		<el-row type="flex">
			<div style="width:76%">
				<div class="select-box">
					<!-- <div class="gray9 mb10">
						<span class="iconfont icon-tanhao"></span>
						列表页是否违背差旅提示仅供参考,实际以详情页的为准
					</div> -->
					<div class="filterTagBox">
						<span class="tag" v-for="(item, index) in filterTags" :key="index">
							{{ item.name }}
							<span class="iconfont icon-del icon-guanbi" @click="bindDelTag(index, item)"></span>
						</span>
					</div>
					<el-row type="flex">
						<el-dropdown :hide-on-click="false" class="paixu-box" style="border-left:1px solid #ececec;" @command="handleCommand" placement="bottom-start">
							<span class="el-dropdown-link">智能排序</span>
							<el-dropdown-menu slot="dropdown" class="hotel-menu2 sort-menu" style="width:280px">
								<el-dropdown-item v-for="(item, index) in sortArry" :key="index" :command="item.code">
									<el-row type="flex" justify="space-between">
										<block v-if="item.code == sortCol">
											<el-row type="flex" justify="space-between" style="width:220px">
												<span class="red mr10">{{ item.name }}</span>
												<span class="iconfont icon-duigou red"></span>
											</el-row>
										</block>
										<block v-else>
											<span class="">{{ item.name }}</span>
										</block>
									</el-row>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<block v-if="hotelfilterInfo != ''">
							<el-dropdown :hide-on-click="false" class="paixu-box" placement="bottom-start">
								<span class="el-dropdown-link">区域位置</span>
								<el-dropdown-menu slot="dropdown" class="hotel-menu2 area-menu" style="width:280px;padding:0px">
									<div class="pr10">
										<el-row type="flex" justify="space-between" class="preference pt10 pb10 pl10" align="middle">
											<span class="red">近距离优先</span>
											<span class="iconfont icon-duigou red"></span>
										</el-row>
										<el-row type="flex" justify="start" class="preference-box">
											<div class="w30 hg30">
												<block v-for="(item, index) in hotelfilterInfo.filterBusinesses" :key="index">
													<div
														style="cursor:pointer;"
														@click="bindBusinessesTag(index)"
														:class="'filteTag ' + (index == businessesIdx ? 'filteTag-active' : '')"
													>
														{{ item.LocationTypeName }}
													</div>
												</block>

												<!-- <div class="filteTag filteTag-active">购物区</div>
											<div class="filteTag">交通</div>
											<div class="filteTag">娱乐</div>
											<div class="filteTag">美食</div> -->
											</div>
											<div class="w70">
												<block v-for="(item, index) in hotelfilterInfo.filterBusinesses[businessesIdx].Businesses" :key="index">
													<div style="cursor:pointer;" class="w70-item" @click="bindSelectFilter(index, 'businesses')">
														<span class="mr10">{{ item.name }}</span>
														<span v-if="item.ischecked" class="iconfont icon-duigou red"></span>
													</div>
												</block>

												<!-- <div
												style="cursor:pointer;"
												class="w70-item"
												@click="bindSelectFilter(index, 'businesses')"
												v-for="(item, index) in hotelfilterInfo.filterBusinesses[businessesIdx].Businesses"
												:key="index"
											>
												{{ item.name }} 
											</div>-->
											</div>
										</el-row>
									</div>
									<el-row type="flex" justify="space-between">
										<el-button
											type="default"
											style="width:50%;background-color: #f5f5f5;color:#999;;border-radius:0px; border:0px;margin-left:0px;"
											@click="clearTag('businesses')"
										>
											清空选择
										</el-button>
										<el-button type="default" style="width:50%;background-color: #ff8f00;color:#fff;border-radius:0px; border:0px;margin-left:0px;">
											确定
										</el-button>
									</el-row>
								</el-dropdown-menu>
							</el-dropdown>
							<el-dropdown :hide-on-click="false" class="paixu-box" placement="bottom-start">
								<span class="el-dropdown-link">价格星级</span>
								<el-dropdown-menu slot="dropdown" class="hotel-menu2 start-menu" style="width:460px;padding:0px">
									<el-row type="flex" align="middle" class="pl15 pt10 pb10">
										<span class="mr10">价格</span>
										<!--<el-input v-model="input" placeholder="￥100" size="mini" style="width:68px" class="mr10"></el-input>
									<span class="mr10">-</span>
									<el-input v-model="input" placeholder="￥200" size="mini" style="width:68px" class="mr10"></el-input>
									<el-button type="default" style="width:68px;background-color: #ff8f00;color:#fff;border-radius:0px; border:0px;margin-left:0px;" size="mini">
										搜索
									</el-button>-->
									</el-row>
									<div class="pl10 pr10 mb20">
										<block v-for="(item, index) in filterPriceList" :key="index">
											<div style="cursor:pointer;" @click="bindSelectFilter(index, 'price')" :class="'priceTag ' + (item.ischecked ? 'priceTag-active' : '')">
												{{ item.name }}
											</div>
										</block>
									</div>
									<el-row type="flex" align="middle" class="pl15 pt10 pb10"><span class="mr10">酒店星级</span></el-row>
									<div class="pl10 pr10 mb20">
										<block v-for="(item, index) in hotelfilterInfo.filterStars" :key="index">
											<div style="cursor:pointer;" @click="bindSelectFilter(index, 'star')" :class="'priceTag ' + (item.ischecked ? 'priceTag-active' : '')">
												{{ item.name }}
											</div>
										</block>
									</div>
									<el-row type="flex" justify="space-between">
										<el-button
											type="default"
											style="width:50%;background-color: #f5f5f5;color:#999;;border-radius:0px; border:0px;margin-left:0px;"
											@click="clearTag('price')"
										>
											清空选择
										</el-button>
										<el-button type="default" style="width:50%;background-color: #ff8f00;color:#fff;border-radius:0px; border:0px;margin-left:0px;">
											确定
										</el-button>
									</el-row>
								</el-dropdown-menu>
							</el-dropdown>

							<el-dropdown :hide-on-click="false" class="paixu-box" placement="bottom-start">
								<span class="el-dropdown-link">酒店品牌</span>
								<el-dropdown-menu slot="dropdown" class="hotel-menu2 brand-menu" style="width:280px;padding:0px">
									<div class="pr10">
										<el-row type="flex" justify="start" class="preference-box">
											<div class="w30 hg30">
												<div class="filteTag filteTag-active">酒店品牌</div>
												<!-- <div class="filteTag">购物区</div>
											<div class="filteTag">交通</div>
											<div class="filteTag">娱乐</div>
											<div class="filteTag">美食</div> -->
											</div>
											<div class="w70" style="cursor:pointer;">
												<block v-for="(item, index) in hotelfilterInfo.filterBrands" :key="index">
													<div class="w70-item" @click="bindSelectFilter(index, 'brand')">
														<span class="mr10">{{ item.name }}</span>
														<span v-if="item.ischecked" class="iconfont icon-duigou red"></span>
														<!-- {{ item.name }}
												<span v-if="item.ischecked" class="iconfont icon-duigou red"></span> -->
													</div>
												</block>
											</div>
										</el-row>
									</div>
									<el-row type="flex" justify="space-between">
										<el-button
											type="default"
											style="width:50%;background-color: #f5f5f5;color:#999;;border-radius:0px; border:0px;margin-left:0px;"
											@click="clearTag('brand')"
										>
											清空选择
										</el-button>
										<el-button type="default" style="width:50%;background-color: #ff8f00;color:#fff;border-radius:0px; border:0px;margin-left:0px;">
											确定
										</el-button>
									</el-row>
								</el-dropdown-menu>
							</el-dropdown>
						</block>
						<div class="paixu-box hotel-indexv2">
							<el-row type="flex" align="middle">
								<el-input
									v-model="HotelSearchInfo.KeyWord"
									placeholder="酒店名/商圈"
									size="mini"
									style="width:168px"
									@focus="bindShowKeyWordBox($event, true)"
									@input="searchKeyWordInput"
									@click.stop.native
								></el-input>
								<el-button type="danger" size="mini" class="hotel-btn110" @click="bindSearchByKeyWord">搜索</el-button>
								<div class="ft14 mr20" style="margin-left:120px;">
									已搜索出
									<span class="red">{{ pageTotal }}</span>
									家酒店
								</div>
								<keywords
									ref="keywordsRef"
									:ShowKeyWordBox="ShowKeyWordBox"
									:cityCodeKeyWord="cityCodeKeyWord"
									@bindShowKeyWordBox="bindShowKeyWordBox"
									@bindHideKeyWordBox="bindHideKeyWordBox"
									@selectKeyWord="selectKeyWord"
									@click.stop.native
								></keywords>
							</el-row>
						</div>
					</el-row>
				</div>
				<yellowLoading v-if="isShowLoading"></yellowLoading>
				<flightNoData msg="酒店" v-if="isNoData"></flightNoData>

				<el-row type="flex" class="public-table" align="middle" v-for="(item, index) in HotelListInfo" :key="index">
					<div class="product-img" @click="bindNext" :data-id="item.HotelID"><img :src="item.HotelImg" /></div>
					<div class="product-txt">
						<p class="clearfix">
							<a class="name" style="cursor:pointer;" href="javascript:;" @click="bindNext" :data-id="item.HotelID">
								<span class="imgbox">{{ index + 1 }}</span>
								<span class="name-txt">{{ item.HotelName }}({{ item.HotelEName }})</span>
							</a>
						</p>
						<div class="clearfix mt5">
							<span class="fl gray6">酒店星级：</span>
							<div class="star-box star-box2 fl">
								<el-rate v-model="item.Star" disabled text-color="#ff9900" score-template="{value}" void-icon-class="gray9"></el-rate>
							</div>
							<span class="fl gray6 ml10">{{ item.StarName }}</span>

							<span class="fl gray6 ml10" style="color: #ff9900;" v-if="item.ConsumerRating != 0">{{ item.ConsumerRating }}</span>
						</div>
						<el-row class="address" type="flex" align="middle">
							<span class="ad-detail">地址:{{ item.Address }}</span>
							<a href="javascript:;" class="map ml20" @click="bindShowMap(item)">查看地图</a>
						</el-row>
						<div class="mt5" v-if="item.DistanceStr != ''">距市中心直线约{{ item.DistanceStr }}公里</div>
						<p class="service-box mt5">
							<span class="iconfont icon-hotel icon-i"></span>
							<span class="iconfont icon-mudedi icon-i"></span>
						</p>
					</div>
					<div class="product-price-box clearfix">
						<div>
							<div class="price-box6">
								<p class="price">
									<i class="ft14 gray3">￥</i>
									<i class="ft22">{{ item.Price }}</i>
									<i class="ft14 gray3">起</i>
								</p>
								<el-button
									@click="bindNext"
									:data-id="item.HotelID"
									type="primary"
									style="background-color: #fe6156;color:#fff;border:1px solid #fe6156;width:120px"
								>
									查看详情
								</el-button>
							</div>
							<div class="price-box6" style="display:none;">
								<p class="price">
									<i class="ft14 gray3">￥</i>
									<i class="ft22">{{ item.Price }}</i>
									<i class="ft14 gray3">起</i>
								</p>
								<el-button type="primary" style="background-color: #fe6156;color:#fff;border:1px solid #fe6156;width:120px">查看详情</el-button>
							</div>
						</div>
					</div>
				</el-row>
				<el-row type="flex" justify="center"><pagination @handleCurrentChange="handleCurrentChange" :pageTotal="pageTotal" :pageSize="pagesize"></pagination></el-row>
			</div>
			<div class="rightbox2">
				<!-- 地图样式 -->
				<div id="mapContainer">
					<iframe
						v-if="showInitMap"
						id="itomFr"
						name="leftframe"
						:src="mapsUrl"
						marginwidth="10"
						marginheight="10"
						frameborder="no"
						width="100%"
						scrolling="yes"
						height="500px "
					></iframe>
					<div class="">
						<div class="loacatebox">
							<!-- <div class="hotel-name">酒店</div>
							<div class="imgbox">1</div> -->
						</div>
					</div>
				</div>
			</div>
		</el-row>
		<!-- <mapModal :mapShow="mapShow" @clickDelAction="clickDelmap"></mapModal> -->

		<el-dialog class="des-modal" :visible.sync="mapShow" width="900px" show-close="false" title="地图" left :before-close="(showInitMap = true)">
			<div class="hotel-list-box" style="width:100%;margin-left:0px">
				<!-- <span class="iconfont icon-chalv-guanbi guanbi-anniu" @click="mapShow = false;showInitMap=true"></span> -->
				<div class="mapbox" style="height:500px;width:100%">
					<!-- <div class="loacatebox">
						<div class="hotel-name">酒店</div>
						<div class="imgbox">1</div>
					</div> -->
					<iframe
						v-if="mapShow"
						id="itomFr2"
						name="leftframe"
						:src="mapInUrl"
						marginwidth="10"
						marginheight="10"
						frameborder="no"
						scrolling="no"
						style="width: 100%;height: 500px;"
					></iframe>
				</div>
			</div>
		</el-dialog>

		<!-- <desModal :titleRight="mapShow" widthNum="70" @clickDelAction="mapShow = false" :showClose="false" class="map-modal">
			<div class="hotel-list-box" style="width:100%;margin-left:0px">
				<span class="iconfont icon-chalv-guanbi guanbi-anniu" @click="mapShow = false;showInitMap=true"></span>
				<div class="mapbox" style="height:500px;width:100%">					
					<iframe
						v-if="mapShow"
						id="itomFr"
						name="leftframe"
						:src="mapInUrl"
						marginwidth="10"
						marginheight="10"
						frameborder="no"						
						scrolling="no"						
						style="width: 100%;height: 500px;"
					></iframe>
				</div>
			</div>
		</desModal> -->
	</div>
</template>

<script>
import search from './component/search.vue';
import yellowLoading from '../../components/yellowLodaing.vue';
import FlightLoading from '../../components/flightLoading.vue';
import flightNoData from '../../components/flightNoData.vue';
import SearchReply from './component/SearchReply.vue';
import pagination from '../../components/pagination.vue';
import keywords from './component/keywords.vue';
// import maps from 'qqmap';
import mapModal from './component/mapModal.vue';
import desModal from '../../components/desModal.vue';

import {
	adults,
	childrens,
	HotelSearchInfo
} from '@/data/staticData';
import {
	getHotelSearchInfo,
	setHotelSearchInfo
} from '@/utils/localdatas';

var index = 0;
const cityOptions = ['上海', '北京', '广州', '深圳'];

var util = require('@/utils/util.js');
var hotelApi = require('@/api/hotelApi.js');
var yqfApi = require('@/api/yqfApi.js');

var that;
var searchCount = 0;
var AsyncId;
var serchTimeout;
export default {
	components: {
		flightNoData,
		yellowLoading,
		SearchReply,
		pagination,
		keywords,
		// desModal
		// mapModal
	},
	data() {
		return {
			initMap: false,
			show3: false,
			value: 3.7,
			checked: false,
			activeNames: ['1'],
			input: '',
			formInline: {
				user: '',
				region: '',
				region2: ''
			},
			drawer: false,
			direction: 'btt',
			time2: '',
			time4: '',
			navtab: '',
			fliterselected: '',
			id: '',
			showCity1: false,
			showDate: false,
			roomTypeActive: false,
			type: '',
			textShow: false,
			progress: [{
					name: '选择酒店',
					current: true
				},
				{
					name: '选择房型'
				},
				{
					name: '填写资料'
				},
				{
					name: '确认资料'
				},
				{
					name: '提交预订'
				}
			],
			mapShow: false,
			hotelLocation: [{
					name: '交通',
					current: 'false'
				},
				{
					name: '景点',
					current: 'false'
				},
				{
					name: '购物区',
					current: 'false'
				},
				{
					name: '热门地区',
					current: 'false'
				}
			],
			priceArray: ['200元以下', '200-500元', '500-800元', '800-1200元', '1200元以上'],
			typeArray: ['尊贵型', '豪华型', '舒适型', '经济型', '实惠型', '未评级'],

			sortArry: [
				// {
				// 	code: 'Agreement|asc',
				// 	name: '协议酒店优先',
				// 	isChecked: false
				// },
				{
					code: 'Default|asc',
					name: '综合排序',
					isChecked: false
				},
				{
					code: 'Price|asc',
					name: '低价优先',
					isChecked: false
				},
				{
					code: 'Price|desc',
					name: '高价优先',
					isChecked: false
				},
				{
					code: 'ConsumerRating|desc',
					name: '评分高优先',
					isChecked: false
				},
				{
					code: 'Distance|asc',
					name: '近距离优先',
					isChecked: false
				}
			],
			sortCol: 'Default|asc',
			businessesIdx: 0,

			filterPriceList: [
				// {
				// 	name: '不限',
				// 	code: '',
				// 	MinPrice: 0,
				// 	MaxPrice: 0,
				// 	ischecked: true
				// },
				{
					name: '200元以下',
					code: '0,200',
					ischecked: false
				},
				{
					name: '200-500元',
					code: '200,500',
					ischecked: false
				},
				{
					name: '500-800元',
					code: '500,800',
					ischecked: false
				},
				{
					name: '800-1200',
					code: '800,1200',
					ischecked: false
				},
				{
					name: '1200元以上',
					code: '1200,99999',
					ischecked: false
				}
			],
			filterTags: [],

			dates: [],
			HotelSearchInfo: HotelSearchInfo,

			isNoData: false,
			isShowLoading: false,
			isDoneData: false,
			hotelfilterInfo: '',
			HotelListInfo: [],
			pagesize: 20,
			pageindex: 0,
			pageTotal: 0,
			mapsUrl: '',
			mapInUrl: '',
			showInitMap: false,
			ShowKeyWordBox: false,
			cityCodeKeyWord: '',
			filterStr: ''
		};
	},
	created() {
		that = this;
		var query = that.$route.query;
		console.log(query);
		if (!util.isNullOrEmpty(query.filter)) {
			that.filterStr = query.filter;
		}
		// this.initMap();

		var HotelSearchInfo = getHotelSearchInfo();
		that.HotelSearchInfo = HotelSearchInfo;
		that.dates = [HotelSearchInfo.CheckInDateStr, HotelSearchInfo.CheckOutDateStr];

		getHotelListAsyncId();
	},
	mounted() {
		// window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
		document.addEventListener('click', (e) => {
			// console.log(e.target.className);
			this.$refs.SearchReplyAssembly.bindHotelClick(e);
			this.ShowKeyWordBox = false;
		})
	},
	methods: {
		on2: function(e) {

		},
		bindHideKeyWordBox() {
			that.ShowKeyWordBox = false;
		},
		bindShowKeyWordBox(e, flag) {
			try {
				e.currentTarget.select();
			} catch (e) {

			}
			that.cityCodeKeyWord = that.HotelSearchInfo.Countrycode + that.HotelSearchInfo.Citycode;
			if (that.cityCodeKeyWord == "") {
				that.$alert('请先选择城市');
				return;
			}
			that.showCity = false;
			that.ShowKeyWordBox = flag;
			that.$refs.keywordsRef.bindGetHotelSearchLog(that.cityCodeKeyWord);
		},
		searchKeyWordInput(e) {
			console.log(e);
			that.$refs.keywordsRef.searchKeyWordInput(e);
			// this.$refs.cityAssembly.$emit("searchInput",e);
		},
		//选择关键字
		selectKeyWord(keyWord, filterStr) {
			console.log(keyWord);
			console.log(filterStr);
			that.HotelSearchInfo.KeyWord = keyWord;
			that.filterStr = filterStr;
			that.ShowKeyWordBox = false;
		},

		//选择筛选
		bindSelectFilter(idx, filterType) {
			that.HotelSearchInfo.KeyWord = '';
			switch (filterType) {
				case 'star':
					that.hotelfilterInfo.filterStars[idx].ischecked = !that.hotelfilterInfo.filterStars[idx].ischecked;
					break;
				case 'brand':
					that.hotelfilterInfo.filterBrands[idx].ischecked = !that.hotelfilterInfo.filterBrands[idx].ischecked;
					break;
				case 'price':
					that.filterPriceList[idx].ischecked = !that.filterPriceList[idx].ischecked;
					break;

				case 'businesses':
					that.hotelfilterInfo.filterBusinesses[that.businessesIdx].Businesses[idx].ischecked = !that.hotelfilterInfo.filterBusinesses[that.businessesIdx].Businesses[idx].ischecked;
					break;
			}
			that.setFilter();
		},
		//设置标签
		setFilter() {
			var filterTags = [];
			//星级
			var index = 0;
			that.hotelfilterInfo.filterStars.forEach(function(item) {
				if (item.ischecked) {
					var filterTag = {
						name: item.name,
						code: item.code,
						idx: index,
						filterStr: "star"
					};
					filterTags.push(filterTag);
				}
				index++;
			});
			//价格
			index = 0;
			that.filterPriceList.forEach(function(item) {
				if (item.ischecked) {
					var filterTag = {
						name: item.name,
						code: item.code,
						idx: index,
						filterStr: "price"
					};
					filterTags.push(filterTag);
				}
				index++;
			});
			//品牌
			index = 0;
			that.hotelfilterInfo.filterBrands.forEach(function(item) {
				if (item.ischecked) {
					var filterTag = {
						name: item.name,
						code: item.code,
						idx: index,
						filterStr: "brand"
					};
					filterTags.push(filterTag);
				}
				index++;
			});
			//商圈
			for (var i = 0; i < that.hotelfilterInfo.filterBusinesses.length; i++) {
				index = 0;
				that.hotelfilterInfo.filterBusinesses[i].Businesses.forEach(function(item) {
					if (item.ischecked) {
						var filterTag = {
							name: item.name,
							code: item.code,
							idx: index,
							BusinessesIdx: i,
							filterStr: "businesses"
						};
						filterTags.push(filterTag);
					}
					index++;
				});
			}
			that.filterTags = filterTags;
			console.log(filterTags);
			getHotelListAsyncResult();
		},
		//删除标签
		bindDelTag(idx, item) {
			// that.filterTags[idx].sp
			switch (item.filterStr) {
				case "star":
					that.hotelfilterInfo.filterStars[item.idx].ischecked = false;
					break;
				case "brand":
					that.hotelfilterInfo.filterBrands[item.idx].ischecked = false;
					break;
				case "price":
					that.filterPriceList[item.idx].ischecked = false;
					break;
				case "businesses":
					that.hotelfilterInfo.filterBusinesses[item.BusinessesIdx].Businesses[item.idx].ischecked = false;
					break;
			}
			that.filterTags.splice(idx, 1);
			getHotelListAsyncResult();
		},
		//清空选择
		clearTag(filterStr) {
			switch (filterStr) {
				case "brand":
					that.hotelfilterInfo.filterBrands.forEach(function(item) {
						item.ischecked = false;
					});
					break;
				case "price":
					that.filterPriceList.forEach(function(item) {
						item.ischecked = false;
					});
					that.hotelfilterInfo.filterStars.forEach(function(item) {
						item.ischecked = false;
					});
					break;
				case "businesses":
					for (var i = 0; i < that.hotelfilterInfo.filterBusinesses.length; i++) {
						that.hotelfilterInfo.filterBusinesses[i].Businesses.forEach(function(item) {
							item.ischecked = false;
						});
					}
					break;
			}
			that.setFilter();
		},
		//品牌
		bindSelectBrandFilter(e) {
			that.hotelfilterInfo.filterBrands[e].ischecked = !that.hotelfilterInfo.filterBrands[e].ischecked;
		},
		//价格
		bindSelectPrice(e) {
			that.filterPriceList[e]._checked = !that.filterPriceList[e]._checked;
		},
		//选择商业地段
		bindBusinessesTag(e) {
			that.businessesIdx = e;
		},
		//排序
		handleCommand(command) {
			that.sortCol = command;
			// this.$message('click on item ' + command);
			getHotelListAsyncResult();
		},
		filterItemClick(idx, typestr) {
			console.log(idx, typestr);
			this.sortArry[idx].isChecked = !this.sortArry[idx].isChecked;
		},

		bindShowMap(item) {
			let coordinates = [];
			let coordinate = {
				Latitude: item.Latitude,
				Longitude: item.Longitude,
				name: item.HotelName,
				Address: item.Address,
				// HotelName:HotelListInfo[i].HotelName,
			};
			coordinates.push(coordinate);
			// let mapUrl="http://fhlocal.yiqifei.com/MapAPI/Index?coordinatesJSON="+JSON.stringify(coordinates);
			let coordinatesJSON = JSON.stringify(coordinates);

			that.mapInUrl = util.mapApiUrl + "?coordinatesJSON=" + coordinatesJSON;
			console.log(that.mapInUrl);
			that.mapShow = true;
			that.showInitMap = false;

		},

		showmap2() {
			this.drawer = true;
		},

		guanjianzi() {
			this.textShow = 'true';
		},
		guanbi2() {
			this.textShow = 'false';
		},

		roomType() {
			this.roomTypeActive = true;
		},
		hidemap() {
			this.drawer = false;
		},

		handleChange(val) {
			console.log(val);
		},
		roomComfirm() {
			this.roomTypeActive = false;
		},
		roomDel() {
			this.roomTypeActive = false;
		},

		clickAir(index, showDate) {
			this.showDate = showDate;
			this.type = index;
			console.log(index, showDate);
		},
		changeTimeDel() {
			this.showDate = false;
		},
		showCity() {
			this.showCity1 = true;
		},
		clickCityDel() {
			this.showCity1 = false;
		},

		changeTime(time, type, today, id) {
			var that = this;
			this.time = time;
			console.log(this.type, today);
			if (this.type == 1) {
				console.log(time < today);
				if (time < today) {
					return false;
				} else {
					that.showDate = false;
					that.time1 = time;
				}
			} else if (this.type == 2) {
				index++;
				console.log(index, id);
				if (index == 1) {
					index++;
					that.time2 = time;
					that.id = index;
					console.log(that.time2, index);
					console.log(that.time4);
					return false;
				} else if (index == 3) {
					that.time4 = time;
					that.showDate = false;
					index = 0;
					that.id = index;
				}
			} else if (this.type == 3) {
				that.time3 = time;
				that.showDate = false;
			}
		},
		clickDelmap() {
			this.mapShow = false;
		},
		viewMap() {
			this.mapShow = true;
		},
		// 初始化地图
		// initMap() {
		// 	let that = this;
		// 	maps.init('K2PBZ-2HEW3-ZPD35-YMKJE-OUZKJ-LQBUT', () => {
		// 		console.log(maps);
		// 		let latLon = new qq.maps.LatLng(26.326248944008693, 116.35539315640926);
		// 		let myOpts = {
		// 			zoom: 14,
		// 			center: latLon,
		// 			mapTypeId: qq.maps.MapTypeId.ROADMAP
		// 		};
		// 		that.map = new qq.maps.Map(document.getElementById('mapContainer'), myOpts);
		// 	});
		// },
		//查看详情
		bindNext(e) {
			var id = e.currentTarget.dataset.id;

			that.$router.push({
				path: '/Hotel/detail',
				query: {
					hotelid: id
				}
			});
		},
		//分页
		handleCurrentChange(page) {
			that.isShowLoading = true;
			console.log(`当前页: ${page}`);
			that.pageindex = page - 1;
			getHotelListAsyncResult();
		},
		bindSearchByKeyWord(e) {
			that.pageindex = 0;
			getHotelListAsyncResult();
		},
		//重新搜索
		bindSearchHotel(e) {
			that.isNoData = false;
			that.isShowLoading = true;

			that.HotelListInfo = [];
			that.pageTotal = 0;
			hotelApi.GetHotelSearch({
				hotelsearchinfo: JSON.stringify(that.HotelSearchInfo)
			}, function(result) {
				// console.log(result);;
				if (result.code == 0) {
					that.HotelSearchInfo = result.data.HotelSearchInfo;
					// console.log(HotelSearchInfo);
					getHotelListAsyncId();
				} else {
					that.$message.error(result.msg);
				}
			});
		}
	}
};

//获取搜索的id
function getHotelListAsyncId() {
	that.isShowLoading = true;
	hotelApi.GetHotelListAsyncId({
		hotelsearchinfo: JSON.stringify(that.HotelSearchInfo)
	}, function(result) {
		// console.log(result);;
		if (result.code != 0) {
			that.isShowLoading = false;
			that.isNoData = true;
			return;
		} else {
			AsyncId = result.data.AsyncId;
			var hotelfilterInfo = result.data.filterInfo;

			// console.log(hotelfilterInfo);
			that.hotelfilterInfo = hotelfilterInfo;
			// that.showInitMap=true;
			getHotelListAsyncResult();
		}
	});
}
//根据ID获取列表数据
function getHotelListAsyncResult() {
	that.isNoData = false;
	that.HotelListInfo = [];
	let fiter_location = "";
	let fiter_price = "";
	let fiter_star = "";
	let fiter_brand = "";
	let fiter_vendor = "";

	var filterTags = that.filterTags;
	if (filterTags.length > 0) {
		filterTags.forEach(function(item) {
			switch (item.filterStr) {
				case "star":
					fiter_star += item.code + '|';
					break;
				case "brand":
					fiter_brand += item.code + '|';
					break;
				case "price":
					fiter_price += item.code + '|';
					break;
				case "businesses":
					fiter_location += item.code + '|';
					break;
			}
		})
	}
	var parm = {
		AsyncId: AsyncId,
		fiter_location: fiter_location,
		fiter_price: fiter_price,
		fiter_star: fiter_star,
		fiter_brand: fiter_brand,
		fiter_vendor: fiter_vendor,
		filterStr: that.filterStr,
		sort: that.sortCol,
		keyword: that.HotelSearchInfo.KeyWord,
		pageindex: that.pageindex
	};

	hotelApi.GetHotelListAsyncResult(parm, function(result) {
		// console.log(result);;
		// that.isDoneData = result.data.isComplete;
		var isComplete = result.data.isComplete;
		console.log(isComplete);
		that.pageTotal = result.data.TotalResults;
		if (result.code != 0) {
			that.isShowLoading = false;
			that.isNoData = true;
		} else {
			var _HotelListInfo = result.data.HotelListInfo;
			console.log(_HotelListInfo.length);
			that.HotelListInfo = _HotelListInfo;
			if (_HotelListInfo.length != 0) {
				initTXMap();
			}
			if (!isComplete) {
				if (_HotelListInfo.length == 0 || _HotelListInfo.length < that.pagesize) {
					serchTimeout = setTimeout(function() {
						getHotelListAsyncResult();
					}, 1000);
				} else {
					that.isShowLoading = false;
				}
				GetHotelListAsyncResultCount();
			} else {
				that.filterStr = '';
				that.isShowLoading = false;
				if (_HotelListInfo.length == 0) {
					that.isNoData = true;
				} else {

				}
			}
			// if (!isComplete) {
			// 	if (_HotelListInfo.length == 0) {
			// 		serchTimeout = setTimeout(function() {
			// 			getHotelListAsyncResult();
			// 		}, 2000);
			// 	}
			// 	else{

			// 	}
			// } else {
			// 	if (_HotelListInfo.length == 0) {
			// 		that.isNoData = true;
			// 	} else {
			// 		that.isShowLoading = false;
			// 	}
			// }
			// 	let isNodata = false;
			// 	// if (that.HotelListInfo == '' && _HotelListInfo == '') {
			// 	// 	isNodata = true;
			// 	// }
			// 	that.isShowLoading = false;
			// 	// that.isNodata = isNodata;
			// }
			// that.isLoading = false;
		}
		// that.isShowLoading = false;
	});
}
function GetHotelListAsyncResultCount() {
	var parm = {
		AsyncId: AsyncId
	};

	hotelApi.GetHotelListAsyncResultCount(parm, function(result) {
		// console.log(result);;
		// that.isDoneData = result.data.isComplete;
		var isComplete = result.data.isComplete;
		console.log(isComplete);
		that.pageTotal = result.data.TotalResults;
		if (result.code == 0) {
			if (!isComplete) {
				serchTimeout = setTimeout(function() {
					GetHotelListAsyncResultCount();
				}, 2000);
			}
		}		
	});
}
function initTXMap() {
	let coordinates = [];
	let HotelListInfo = that.HotelListInfo;
	for (var i = 0; i < HotelListInfo.length; i++) {
		let coordinate = {
			Latitude: HotelListInfo[i].Latitude,
			Longitude: HotelListInfo[i].Longitude,
			name: HotelListInfo[i].HotelName,
			Address: HotelListInfo[i].Address,
		};

		coordinates.push(coordinate);
	}

	// let mapUrl="http://fhlocal.yiqifei.com/MapAPI/Index?coordinatesJSON="+JSON.stringify(coordinates);
	let coordinatesJSON = JSON.stringify(coordinates);

	that.mapsUrl = util.mapApiUrl + "?coordinatesJSON=" + coordinatesJSON;
	// console.log(that.mapsUrl);
	that.showInitMap = true;
}
</script>
<style lang="scss">
@import '../../style/Hotel/hotel.scss';
</style>
