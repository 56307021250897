<template>
	<div class="filter-b">
		<div class="hotel filter select-box clearfix">
			<span class="ft14 ml10 mr10">筛选</span>
			<span class="iconfont current i-block">
				<i class="transit">
					综合排序
					<i class="iconfont icon-xia ml10 arrow-active"></i>
					<i class="iconfont icon-iconfontxiangshangjiantou ml10"></i>
				</i>
			</span>
			<span class="iconfont current i-block">
				<i class="transit">
					价格
					<i class="iconfont icon-xia ml10 arrow-active"></i>
					<i class="iconfont icon-iconfontxiangshangjiantou ml10"></i>
				</i>
			</span>
			<span class="iconfont current i-block">
				<i class="transit">
					评分
					<i class="iconfont icon-xia ml10 arrow-active"></i>
					<i class="iconfont icon-iconfontxiangshangjiantou ml10"></i>
				</i>
			</span>
			<span class="iconfont current i-block">
				<i class="transit">
					星级
					<i class="iconfont icon-xia ml10 arrow-active"></i>
					<i class="iconfont icon-iconfontxiangshangjiantou ml10"></i>
				</i>
			</span>
			
			<div class="i-block">
				<el-input placeholder="酒店名/商圈" v-model="input2">
				    <template slot="append"><el-button type="" style="background: #ccc;color: #333;border:1px solid  #ccc" class="hotel-btn10">搜索</el-button></template>
				</el-input>
				
			</div>
			<div class="fr ft18"><i class="ml10 mr10">已搜索出
					24089
					家
					酒店(有房价酒店
					24089
					家)</i></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'search',
	}
</script>

<style>
</style>
